.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header > .menu {
  display: none;

}

.App-header {
  color: white;
  font-family: Georgia, 'Times New Roman', Times, serif;
  position: fixed;
  z-index: 100;
  top: 0; 
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  background-color: rgba(10,10,10,0.3);
  /* background-image: linear-gradient(to bottom, rgba(0,0,0,0.2), rgba(181, 158, 95,0.5)); */
  border-bottom: 2px solid rgb(181, 158, 95);
  align-items: center;
}

.App-header > * {
  margin: 10px;
}

/* .App-header > .middle > *:first-child {
  border-radius: 5px 0 0 5px;
}

.App-header > .middle > *:last-child {
  border-radius: 0 5px 5px 0;
} */

.donate-button {
  background-color: rgb(159, 84, 84) !important;
}


.App-header > .middle > a, .App-header > .right > a, .App-header > .left > a {
  text-decoration: none;
}

.App-header > .middle > a > * {
  cursor: pointer;
  border: 0.25px solid black;
  padding: 5px 20px;
  color: white;
  background-color: rgb(181, 158, 95);
}

.App-header > .right > a > * {
  cursor: pointer;
  border: 0.25px solid black;
  padding: 5px 20px;
  color: white;
  background-color: rgb(181, 158, 95);
}

.App-header > .middle > a> *:hover {
  background-color: lightgray;
  color: black;
}

.App-header > .right > a> *:hover {
  background-color: rgb(217, 184, 184) !important;
  color: black;
}

.App-header > .left > * {
  font-weight: 900;
  text-shadow: 3px 3px 3px rgb(181, 158, 95);
  color: white;
}

.App-header > .middle {
  display: inline-flex;
  justify-content: center;
  width: 70%;
}

.App-header > .right {
  display: inline-flex;
  justify-content: right;
  width: 15%;
}

.App-header > .left {
  display: inline-flex;
  justify-content: left;
  width: 15%;
}

.App-header > .menu {
  width: 50%;
}

.App-body {
  background-image: url('./resources/images/aq-background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
