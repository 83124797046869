.Box-contact-form {
    display: inline-flex;
    flex-direction: column;
}

.Box-contact-form > * {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.Box-contact-form > * > * > span {
    justify-content: left;
    display: inline-flex;
    width: 100%;
    padding-left: 5px;
    font-weight: 700;
}

.Box-contact-form > * > * > input {
    padding: 10px;
    border-radius: 10px;
    border: 0.25px solid black;
}