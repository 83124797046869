.image-practicioner {
    background-image: url('../resources/images/practicioner.jpg');
}

.image-volunteer {
    background-image: url('../resources/images/volunteer.jpg');
}

.image-benefactor {
    background-image: url('../resources/images/benefactor.jpg');
}

.image-partner {
    background-image: url('../resources/images/partner.jpg');
}