.Page-body {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: auto;
    /* margin-bottom: 30px; */
}

.Page-body::-webkit-scrollbar {
    width: 0;
}

.Page-title {
    background-color: rgba(107, 169, 152,0.3);
    border-top: 2px solid rgb(107, 169, 152);
    border-bottom: 2px solid rgb(107, 169, 152);
    display: inline-flex;
    justify-content: center;
    font-family: Arial, Helvetica, sans-serif;
    align-items: center;
    padding: 5px;
    font-weight: 900;
    text-shadow: 2px 2px 2px black;
    margin-bottom: 20px;
}

.Page-box-body {
    margin-left: 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.Page-box-items {
    margin-top: 10px;
    display: inline-flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.Page-box-items > * {
    width: 300px;
    min-height: 320px;
    background-color: rgba(107, 169, 152, 0.5);
    border: 1px solid black;
    margin: 5px;
    border-radius: 10px;
    position: relative;
    justify-content: center;
}

.Box-title {
    background-color: rgb(181, 158, 95);
    border-radius: 10px 10px 0 0;
    padding: 5px;
    border-bottom: 1px solid black;
}

.Box-buttons {
    position: absolute;
    bottom: 10px;
    display: flex;
    justify-content: center;
    width: 100%;
}

.Box-button {
    background-color: rgb(181, 158, 95);
    border: 0.25px solid black;
    padding: 5px;
}

.Box-image {
    height: 200px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.Box-summary {
    margin-bottom: 60px;
    text-align: justify;
    padding: 5px;
}

.Page-body {
    display: flex;
    flex-direction: column;

}

.Page-body-row {
    font-weight: 600;
    display: inline-flex;
    font-size: 2ch;
    color: black;
    border: 1px solid black;
    padding: 10px;
    border-radius: 10px;
    text-align: justify;
    flex-direction: row;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 20px;
    background-image: linear-gradient(to right, rgba(255,255,255,0.7), rgba(55, 133, 127, 0.4), rgba(255,255,255,0.7), rgba(133, 121, 55, 0.4));
}

.Page-body-row:nth-child(odd) {
    background-image: linear-gradient(to right, rgba(255,255,255,0.7), rgba(55, 133, 127, 0.4), rgba(255,255,255,0.7), rgba(133, 121, 55, 0.4));
}

.Page-body-row:last-child {
    margin-bottom: 50px !important;
}

.Page-body-image {
    height: 200px;
    width: 20%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-right: 10px;
    margin-left: 10px;
    /* border-radius: 50%; */
}

.Page-body-text {
    width: 80%;
    margin-right: 10px;
    margin-left: 10px;
    /* background-color: rgba(107, 169, 152, 0.5); */
}

.Page-contact-box {
    width: 400px;
    display: inline-block;
}