@media screen and (max-width: 700px) {
    .App-header > .middle, .App-header > .right {
        display: none !important;
    }
    .App-header > .left {
        width: 50% !important;
    }
    .App-header > .menu {
        display: flex !important;
        justify-content: right;
    }
    .mission-statement {
        width: 100% !important;
    }
    .App-footer-minimize {
        display: block !important;
    }
  }