.menu-overlay {
    display: none;
    z-index: 105;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: transparent;
}

.menu-overlay-true {
    display: flex !important;

}

.menu-items {
    display: none;
    position: absolute;
    flex-direction: column;
    z-index: 110;
    top: 40px;
    right: 0;
    /* width: 120px; */
    /* padding: 5px 10px; */
    background-color: rgba(10,10,10,0.7);
    border-radius: 0 0 0 10px;
    border-bottom: 1px solid goldenrod;
    border-left: 1px solid goldenrod;
}

.menu-true {
    display: inline-flex !important;
}

.menu > span {
    flex-direction: column;
}

.menu-items > a {
    display: inline-flex;
    text-decoration: none;
    color: white;
    padding: 3px 10px;
    justify-content: left;
}

.menu-items > a:last-child {
    border-radius: 0 0 0 10px;
    padding-bottom: 10px;
}

.menu-items > a:nth-child(odd) {
    background-color: rgba(218, 165, 32, 0.3);
}
