.image-angel-girl {
    background-image: url('../resources/images/christophe-van-der-waals-h-yb5TjYJ-I-unsplash.jpg');
}

.image-clouds {
    background-image: url('../resources/images/noorulabdeen-ahmad-lpcaOpVIzx8-unsplash.jpg');
}

.image-wings {
    background-image: url('../resources/images/nico-frey-JfknhSgANFQ-unsplash.jpg');
}

.image-feather {
    background-image: url('../resources/images/javardh-FL6rma2jePU-unsplash.jpg');
}

.vert-center {
    /* border-bottom: 2px solid black;
    border-top: 2px solid black; */
    height: max-content;
    width: 90%;
    /* background-image: linear-gradient(to right, rgba(255,255,255,0.3), rgbA(218, 165, 32, 0.7)); */
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
}
.under-construction-notice {
    font-size: 5ch;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    text-shadow: 3px 3px 5px black;
}