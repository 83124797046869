@media screen and (max-width: 700px) {
    .App-header > .middle, .App-header > .right {
        display: none !important;
    }
    .App-header > .left {
        width: 50% !important;
    }
    .App-header > .menu {
        display: flex !important;
        justify-content: right;
    }
    .mission-statement {
        width: 100% !important;
    }
    .App-footer-minimize {
        display: block !important;
    }
  }
.menu-overlay {
    display: none;
    z-index: 105;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: transparent;
}

.menu-overlay-true {
    display: flex !important;

}

.menu-items {
    display: none;
    position: absolute;
    flex-direction: column;
    z-index: 110;
    top: 40px;
    right: 0;
    /* width: 120px; */
    /* padding: 5px 10px; */
    background-color: rgba(10,10,10,0.7);
    border-radius: 0 0 0 10px;
    border-bottom: 1px solid goldenrod;
    border-left: 1px solid goldenrod;
}

.menu-true {
    display: inline-flex !important;
}

.menu > span {
    flex-direction: column;
}

.menu-items > a {
    display: inline-flex;
    text-decoration: none;
    color: white;
    padding: 3px 10px;
    justify-content: left;
}

.menu-items > a:last-child {
    border-radius: 0 0 0 10px;
    padding-bottom: 10px;
}

.menu-items > a:nth-child(odd) {
    background-color: rgba(218, 165, 32, 0.3);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.givebutter-bubble {
  background-color: rgba(22, 182, 209, 0.5) !important;
}

.givebutter-modal-content::-webkit-scrollbar {
  width: 0px;
}
.Page-body {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: auto;
    /* margin-bottom: 30px; */
}

.Page-body::-webkit-scrollbar {
    width: 0;
}

.Page-title {
    background-color: rgba(107, 169, 152,0.3);
    border-top: 2px solid rgb(107, 169, 152);
    border-bottom: 2px solid rgb(107, 169, 152);
    display: inline-flex;
    justify-content: center;
    font-family: Arial, Helvetica, sans-serif;
    align-items: center;
    padding: 5px;
    font-weight: 900;
    text-shadow: 2px 2px 2px black;
    margin-bottom: 20px;
}

.Page-box-body {
    margin-left: 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.Page-box-items {
    margin-top: 10px;
    display: inline-flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.Page-box-items > * {
    width: 300px;
    min-height: 320px;
    background-color: rgba(107, 169, 152, 0.5);
    border: 1px solid black;
    margin: 5px;
    border-radius: 10px;
    position: relative;
    justify-content: center;
}

.Box-title {
    background-color: rgb(181, 158, 95);
    border-radius: 10px 10px 0 0;
    padding: 5px;
    border-bottom: 1px solid black;
}

.Box-buttons {
    position: absolute;
    bottom: 10px;
    display: flex;
    justify-content: center;
    width: 100%;
}

.Box-button {
    background-color: rgb(181, 158, 95);
    border: 0.25px solid black;
    padding: 5px;
}

.Box-image {
    height: 200px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.Box-summary {
    margin-bottom: 60px;
    text-align: justify;
    padding: 5px;
}

.Page-body {
    display: flex;
    flex-direction: column;

}

.Page-body-row {
    font-weight: 600;
    display: inline-flex;
    font-size: 2ch;
    color: black;
    border: 1px solid black;
    padding: 10px;
    border-radius: 10px;
    text-align: justify;
    flex-direction: row;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 20px;
    background-image: linear-gradient(to right, rgba(255,255,255,0.7), rgba(55, 133, 127, 0.4), rgba(255,255,255,0.7), rgba(133, 121, 55, 0.4));
}

.Page-body-row:nth-child(odd) {
    background-image: linear-gradient(to right, rgba(255,255,255,0.7), rgba(55, 133, 127, 0.4), rgba(255,255,255,0.7), rgba(133, 121, 55, 0.4));
}

.Page-body-row:last-child {
    margin-bottom: 50px !important;
}

.Page-body-image {
    height: 200px;
    width: 20%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-right: 10px;
    margin-left: 10px;
    /* border-radius: 50%; */
}

.Page-body-text {
    width: 80%;
    margin-right: 10px;
    margin-left: 10px;
    /* background-color: rgba(107, 169, 152, 0.5); */
}

.Page-contact-box {
    width: 400px;
    display: inline-block;
}
.image-angel-girl {
    background-image: url(/static/media/christophe-van-der-waals-h-yb5TjYJ-I-unsplash.98eb4308.jpg);
}

.image-clouds {
    background-image: url(/static/media/noorulabdeen-ahmad-lpcaOpVIzx8-unsplash.917b6aa6.jpg);
}

.image-wings {
    background-image: url(/static/media/nico-frey-JfknhSgANFQ-unsplash.c94e2330.jpg);
}

.image-feather {
    background-image: url(/static/media/javardh-FL6rma2jePU-unsplash.b9e41512.jpg);
}

.vert-center {
    /* border-bottom: 2px solid black;
    border-top: 2px solid black; */
    height: -webkit-max-content;
    height: max-content;
    width: 90%;
    /* background-image: linear-gradient(to right, rgba(255,255,255,0.3), rgbA(218, 165, 32, 0.7)); */
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
}
.under-construction-notice {
    font-size: 5ch;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    text-shadow: 3px 3px 5px black;
}
.image-practicioner {
    background-image: url(/static/media/practicioner.e574eeea.jpg);
}

.image-volunteer {
    background-image: url(/static/media/volunteer.e0fb34e7.jpg);
}

.image-benefactor {
    background-image: url(/static/media/benefactor.010b1199.jpg);
}

.image-partner {
    background-image: url(/static/media/partner.991ea554.jpg);
}
.Box-contact-form {
    display: inline-flex;
    flex-direction: column;
}

.Box-contact-form > * {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.Box-contact-form > * > * > span {
    justify-content: left;
    display: inline-flex;
    width: 100%;
    padding-left: 5px;
    font-weight: 700;
}

.Box-contact-form > * > * > input {
    padding: 10px;
    border-radius: 10px;
    border: 0.25px solid black;
}
.App-footer {
  /* position: absolute;  */
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 30px;
  /* background-color: rgba(10,10,10,0.3); */
  /* background-image: linear-gradient(to right, rgba(218, 165, 32, 0.3), rgba(255,255,255,0.3), rgba(218, 165, 32, 0.3)); */
  background-image: linear-gradient(to right, rgba(107, 169, 152, 0.3), rgba(255, 255, 255, 0.3), rgba(218, 165, 32, 0.3));
  /* background-image: linear-gradient(to right, rgba(107, 169, 152, 0.3), rgba(255,255,255,0.3), rgba(107, 169, 152, 0.3)); */
  border-top: 2px solid rgb(181, 158, 95);
  color: black;
}

.App-footer-box {
  display: flex;
  flex-direction: row;
  color: black;
  margin-bottom: 65px;
  text-align: justify;
  border-top: 2px solid goldenrod;
  width: 100%;
}

.App-footer-title {
  color: white;
  text-shadow: 2px 2px 2px black;
  border-bottom: 1px solid dimgray;
  padding: 5px 10px;
  position: relative;
}

.App-footer-content {
  width: 100%;
}

.show-footer-false {
  display: none;
}

.App-footer-minimize {
  display: none;
  position: absolute;
  right: 10px;
  top: 5px;
}

.App-footer-body {
  padding: 5px 10px;
}

.App-footer-row {
  display: flex;
  flex-direction: column;
}

.mission-statement {
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  width: 80%;
  text-align: justify;
  flex-wrap: nowrap;
  white-space: nowrap;
}

.mission-statement>div {
  font-weight: 700;
  display: inline-flex;
  border-bottom: 0.25px solid dimgray;
  text-shadow: 2px 2px 2px black;
  margin-bottom: 10px;
  color: white;
  padding-bottom: 5px;
}

.mission-statement>span {
  display: contents;
  font-weight: 700;
}

.tax-info {
  padding: 5px 10px;
  width: 80%;
  text-align: justify;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header > .menu {
  display: none;

}

.App-header {
  color: white;
  font-family: Georgia, 'Times New Roman', Times, serif;
  position: fixed;
  z-index: 100;
  top: 0; 
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  background-color: rgba(10,10,10,0.3);
  /* background-image: linear-gradient(to bottom, rgba(0,0,0,0.2), rgba(181, 158, 95,0.5)); */
  border-bottom: 2px solid rgb(181, 158, 95);
  align-items: center;
}

.App-header > * {
  margin: 10px;
}

/* .App-header > .middle > *:first-child {
  border-radius: 5px 0 0 5px;
}

.App-header > .middle > *:last-child {
  border-radius: 0 5px 5px 0;
} */

.donate-button {
  background-color: rgb(159, 84, 84) !important;
}


.App-header > .middle > a, .App-header > .right > a, .App-header > .left > a {
  text-decoration: none;
}

.App-header > .middle > a > * {
  cursor: pointer;
  border: 0.25px solid black;
  padding: 5px 20px;
  color: white;
  background-color: rgb(181, 158, 95);
}

.App-header > .right > a > * {
  cursor: pointer;
  border: 0.25px solid black;
  padding: 5px 20px;
  color: white;
  background-color: rgb(181, 158, 95);
}

.App-header > .middle > a> *:hover {
  background-color: lightgray;
  color: black;
}

.App-header > .right > a> *:hover {
  background-color: rgb(217, 184, 184) !important;
  color: black;
}

.App-header > .left > * {
  font-weight: 900;
  text-shadow: 3px 3px 3px rgb(181, 158, 95);
  color: white;
}

.App-header > .middle {
  display: inline-flex;
  justify-content: center;
  width: 70%;
}

.App-header > .right {
  display: inline-flex;
  justify-content: right;
  width: 15%;
}

.App-header > .left {
  display: inline-flex;
  justify-content: left;
  width: 15%;
}

.App-header > .menu {
  width: 50%;
}

.App-body {
  background-image: url(/static/media/aq-background.946d1ed9.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

