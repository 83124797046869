@import 'styles/screens.css';
@import 'styles/menu.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.givebutter-bubble {
  background-color: rgba(22, 182, 209, 0.5) !important;
}

.givebutter-modal-content::-webkit-scrollbar {
  width: 0px;
}