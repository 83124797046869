.App-footer {
  /* position: absolute;  */
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 30px;
  /* background-color: rgba(10,10,10,0.3); */
  /* background-image: linear-gradient(to right, rgba(218, 165, 32, 0.3), rgba(255,255,255,0.3), rgba(218, 165, 32, 0.3)); */
  background-image: linear-gradient(to right, rgba(107, 169, 152, 0.3), rgba(255, 255, 255, 0.3), rgba(218, 165, 32, 0.3));
  /* background-image: linear-gradient(to right, rgba(107, 169, 152, 0.3), rgba(255,255,255,0.3), rgba(107, 169, 152, 0.3)); */
  border-top: 2px solid rgb(181, 158, 95);
  color: black;
}

.App-footer-box {
  display: flex;
  flex-direction: row;
  color: black;
  margin-bottom: 65px;
  text-align: justify;
  border-top: 2px solid goldenrod;
  width: 100%;
}

.App-footer-title {
  color: white;
  text-shadow: 2px 2px 2px black;
  border-bottom: 1px solid dimgray;
  padding: 5px 10px;
  position: relative;
}

.App-footer-content {
  width: 100%;
}

.show-footer-false {
  display: none;
}

.App-footer-minimize {
  display: none;
  position: absolute;
  right: 10px;
  top: 5px;
}

.App-footer-body {
  padding: 5px 10px;
}

.App-footer-row {
  display: flex;
  flex-direction: column;
}

.mission-statement {
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  width: 80%;
  text-align: justify;
  flex-wrap: nowrap;
  white-space: nowrap;
}

.mission-statement>div {
  font-weight: 700;
  display: inline-flex;
  border-bottom: 0.25px solid dimgray;
  text-shadow: 2px 2px 2px black;
  margin-bottom: 10px;
  color: white;
  padding-bottom: 5px;
}

.mission-statement>span {
  display: contents;
  font-weight: 700;
}

.tax-info {
  padding: 5px 10px;
  width: 80%;
  text-align: justify;
}